import { GeocodeResultProps } from "../../../helpers/types/getTypes";

// NEW YORK DEFAULT
export const MAP_DEFAULT = {
  lat: 40.7232274,
  lng: -74.001718,
} as {
  lat: number;
  lng: number;
};

// USA CENTER
export const USA_CENTER = {
  lat: 39.8097343,
  lng: -98.5556199,
} as {
  lat: number;
  lng: number;
};

// USA BORDER LIMITS
const USA_BOUNDS = {
  north: 67.4250519739,
  south: -21.082827843,
  west: -162.1197412536,
  east: -28.3502100036,
};

// PLACE ID USA
export const MAP_USA = "ChIJCzYy5IS16lQRQrfeQ5K5Oxw";

export const boundariesUSA = {
  strokeColor: "#3366FF",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: "#3366FF",
  fillOpacity: 0.4,
};

export const boundariesUSAOutside = {
  strokeColor: "#1A202C",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: "#1A202C",
  fillOpacity: 0.2,
};

// MAP ID
export const MAP_ID: string[] = [process.env.NEXT_PUBLIC_GOOGLE_MAP_ID];

// OPTIONS GOOGLE MAP
export const OPTIONS: google.maps.MapOptions = {
  restriction: {
    latLngBounds: USA_BOUNDS,
    strictBounds: false,
  },
  gestureHandling: "cooperative",
  mapId: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID,
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
  mapTypeControl: false,
};

export const OPTIONS_OLD = {
  restriction: {
    latLngBounds: USA_BOUNDS,
    strictBounds: false,
  },
  gestureHandling: "cooperative",
};

// CREATE RADIUS FROM WAYPOINTS
export const createRadius = (waypoints: number[]) => {
  const rad = {} as any;
  waypoints.map((e, key: number) => {
    rad[key + 1] = e.toString();
  });
  return rad;
};

// LIBRARIES
export const LIBRARIES: (
  | "drawing"
  | "geometry"
  | "localContext"
  | "places"
  | "visualization"
)[] = ["places"];

// MILES TO METERS
export const METER = 1609.344;

export const getCity = (data: any) => {
  let city = "";

  let searching = data.filter((e: any) => {
    if (e.types.includes("locality")) {
      return e;
    } else if (e.types.includes("administrative_area_level_1")) {
      return e;
    }
  });
  if (searching.length > 0) {
    city = searching[0].long_name;
  }

  return city;
};

export const getResultCity = (data: any, type: string) => {
  let city = "";
  const searching = data.filter((e: any) => {
    if (e.types.includes(type)) {
      return e;
    } else if (e.types.includes("locality")) {
      return e;
    } else if (e.types.includes("administrative_area_level_1")) {
      return e;
    }
  });
  if (searching.length > 0) {
    const searching2 = searching[0].address_components.filter((e: any) => {
      if (e.types.includes("locality")) {
        return e;
      } else if (e.types.includes("administrative_area_level_1")) {
        return e;
      }
    });

    if (searching2.length > 0) {
      city = searching[0].formatted_address;
    }
  }

  return city;
};

export const getDynamicResult = (data: GeocodeResultProps, type: string) => {
  let res = "";

  const searching2 = data.address_components.filter((e: any) => {
    if (e.types.includes(type)) {
      return e;
    }
  });

  if (searching2.length > 0) {
    res = searching2[0].long_name;
  }

  return res;
};

export const getResultAddress = (data: any) => {
  let address = "";
  if (data.length > 0) {
    address = data[0].formatted_address;
  }

  return address;
};

// VERSION OF MAP
export const VERSION = "beta";

export const LOADER = {
  googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY,
  libraries: LIBRARIES,
  mapIds: MAP_ID,
  version: VERSION,
};

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getSpesificResultGeocode = (
  data: GeocodeResultProps[],
  type = "locality"
) => {
  const result = data.filter((e: any) => {
    if (e.types.includes(type)) {
      return e;
    }
  });
  if (result.length > 0) {
    return result[0];
  }

  const result_postal_code = data.filter((e: any) => {
    if (e.types.includes("postal_code")) {
      return e;
    }
  });
  if (result_postal_code.length > 0) {
    return result_postal_code[0];
  }

  const result_route = data.filter((e: any) => {
    if (e.types.includes("route")) {
      return e;
    }
  });

  if (result_route.length > 0) {
    return result_route[0];
  }

  const result_country = data.filter((e: any) => {
    if (e.types.includes("country")) {
      return e;
    }
  });

  if (result_country.length > 0) {
    return result_country[0];
  }

  return data[0];
};
