import { useState } from "react";
import { LocationBoldIcon } from "../icon/SvgIcon";
interface Props {
  value?: string;
  name?: string;
  placeholder?: string;
  className?: string;
  onChange?: (e: any) => void;
  onClick?: (e: any) => void;
  onFocus?: (e: any) => void;
  required?: boolean;
  innerRef?: any;
  autoComplete?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  zIndex?: string;
}

const InputSearch = ({
  value,
  name,
  placeholder = "Input text",
  className = "",
  onChange,
  onClick,
  onFocus,
  required = false,
  innerRef,
  autoComplete = "on",
  defaultValue,
  autoFocus,
  readOnly,
  disabled = false,
  zIndex = "",
}: Props) => {
  return (
    <div className={`relative ${zIndex}`}>
      <div className="flex absolute inset-y-0 left-0 items-center pl-3.5 md:pl-4.5 pointer-events-none">
        <div className="text-warning-500">
          <LocationBoldIcon />
        </div>
      </div>
      <input
        ref={innerRef}
        id={`input-${name}`}
        value={value}
        defaultValue={defaultValue}
        type="text"
        className="block py-[0.85rem] md:py-[1.6rem] pr-[6.5rem] pl-10 md:pl-12 w-full text-regular-tight placeholder:text-neutral-500 text-neutral-700 bg-white rounded-full focus:outline-none shadow-lv3"
        placeholder={placeholder}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        readOnly={readOnly || disabled}
      />
      <button
        type="button"
        className="text-white absolute right-3.5 md:right-4.5 bottom-2 md:bottom-3 bg-primary-500 hover:bg-primary-600 focus:outline-none font-medium rounded-full text-small-tight md:text-regular-none py-2 px-4 md:py-4 md:px-8 transition duration-500 ease-in-out"
        onClick={onClick}
      >
        Search
      </button>
    </div>
  );
};

export default InputSearch;
