import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useEffect, useRef, useState } from "react";
import {
  GpsBoldIcon,
  LocationBoldIcon,
  LocationSlashBoldIcon,
} from "../../commons/icon/SvgIcon";
import { LIBRARIES } from "./helper";
import InputSearch from "../../commons/input/InputSearch";

interface Props {
  currentLocation?: () => void;
  changeLocation?: (e: string, v?: any) => void;
  searchShow: (e: any) => void;
  inputSearchOnClick?: (e: any) => void;
  show?: string;
  inputClassName?: string;
  searchResult?: string;
  label?: string;
  hiddenAutocomplete?: boolean;
  disabled?: boolean;
  solo?: boolean;
}

const AutoCompleteMap = ({
  currentLocation,
  changeLocation,
  searchShow,
  inputSearchOnClick,
  show,
  inputClassName = "shadow-lv3",
  searchResult = "",
  label = "Find and Select Location",
  hiddenAutocomplete = false,
  disabled = false,
  solo,
}: Props) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [inputSearch, setInputSearch] = useState("");
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [max, setMax] = useState(false);

  const params = {
    // apiKey: "",
    options: {
      input: "",
      componentRestrictions: {
        country: ["us"],
      },
    },
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService(
      solo
        ? { ...params, apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY }
        : params
    );

  useEffect(() => {
    if (searchResult) {
      setInputSearch(searchResult);
    }
  }, [searchResult]);

  return (
    <>
      <InputSearch
        innerRef={searchRef}
        value={inputSearch}
        name="find"
        placeholder={label}
        onChange={(e) => {
          const val = e.target.value;
          if (val.length > 2) {
            setMax(true);
            getPlacePredictions({ input: val });
          } else {
            setMax(false);
          }
          setOpenSearch(true);
          setInputSearch(val);
          searchShow("open");
        }}
        onClick={inputSearchOnClick}
        onFocus={(e) => {
          const val = e.target.value;
          if (val.length > 2) {
            setMax(true);
            getPlacePredictions({ input: val });
          } else {
            setMax(false);
          }
          setOpenSearch(true);
          searchShow("open");
        }}
        className={inputClassName}
        autoComplete="off"
        disabled={disabled}
      />

      {openSearch && show === "open" && (
        <>
          <div
            className={`mt-3.5 bg-white shadow-lv3 p-4.5 rounded-[0.875rem] flex flex-col space-y-4.5 z-30`}
          >
            {disabled && max && (
              <div className="flex flex-row space-x-2 items-center text-neutral-900 ">
                <div className="text-neutral-400">
                  <LocationBoldIcon />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <div className="text-small-tight font-medium text-neutral-900">
                    Location is Disabled
                  </div>
                  <p className="text-tiny-tight text-neutral-700">
                    {
                      "Since you’re choshing max radius you will get the whole country instead"
                    }
                  </p>
                </div>
              </div>
            )}

            {!disabled && !hiddenAutocomplete && (
              <>
                {/* CURRENT LOCATION */}
                <div
                  className="flex flex-row space-x-2 items-center text-neutral-900 cursor-pointer"
                  onClick={currentLocation}
                >
                  <div className="text-warning-500">
                    <GpsBoldIcon />
                  </div>
                  <div className="text-small-normal ">
                    Use your current location
                  </div>
                </div>
                {inputSearch && !isPlacePredictionsLoading && max && (
                  <>
                    {placePredictions.length > 0 ? (
                      placePredictions.map((e: any, key: number) => (
                        <div
                          key={key}
                          className="flex flex-row space-x-2 items-center text-neutral-900 cursor-pointer"
                          onClick={() => {
                            setOpenSearch(false);
                            searchShow("close");
                            // changeLocation?.(
                            //   e.place_id,
                            //   `${e.terms[0].value}, ${
                            //     e.terms[e.terms.length - 1].value
                            //   }`
                            // );
                            changeLocation?.(e.place_id, e.description);
                            setInputSearch(e.description);
                          }}
                        >
                          <div className="text-primary-200">
                            <LocationBoldIcon />
                          </div>
                          <div className="flex flex-col space-y-1.5">
                            <div className="text-small-tight font-medium">
                              {e.terms[0].value},{" "}
                              {e.terms[e.terms.length - 1].value}
                            </div>
                            <p className="text-tiny-tight text-neutral-700">
                              {e.description}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex flex-row space-x-2 items-center text-neutral-900">
                        <div className="text-neutral-400">
                          <LocationSlashBoldIcon />
                        </div>
                        <div className="flex flex-col space-y-1.5">
                          <div className="text-small-tight font-medium">
                            Location Not Covered
                          </div>
                          <p className="text-tiny-tight text-neutral-700">
                            {
                              "We're truly sorry our services didn't cover that location yet"
                            }
                          </p>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {!max && (
                  <div className="flex flex-row space-x-2 items-center text-neutral-900 ">
                    <div className="text-neutral-400">
                      <LocationBoldIcon />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <div className="text-small-tight font-medium text-neutral-900">
                        Min 3 Characters
                      </div>
                      <p className="text-tiny-tight text-neutral-700">
                        Type at least 3 characters to find locations
                      </p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default AutoCompleteMap;
